import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import store from "./store";
import { App as AppWrapper } from "antd";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ErrorFallbackPage } from "./utils";
import { ErrorBoundary } from "react-error-boundary";
// Translation
import "./i18n";

// CSS
// Default CSS
import "./Assets/CSS/style.css";
// Home
import "./Home/CSS/index.css";
import "./Home/CSS/MediaQuery.css";
// View
import "./View/CSS/index.css";
import "./View/CSS/MediaQuery.css";
// Admin
import "./Admin/CSS/index.css";
import "./Admin/CSS/MediaQuery.css";
// Admin Auth
import "./Admin/Views/Auth/Assets/style.css";
// Auth
import "./Auth/CSS/index.css";
import "./Auth/CSS/MediaQuery.css";
// Components
import "./Components/CSS/style.css";
import "./Components/CSS/MediaQuery.css";

// ToastHandler
import ToastHandler from "./ToastHandler";
import ViewNavigation, { AdminNavigation, ORGSCHOOLNavigation, SubAdminNavigation } from "./Components/data";

// View
const ViewIndex = lazy(() => import("./View/Index"));
const Dashboard = lazy(() => import("./View/Views/Dashboard"));
const Learning = lazy(() => import("./View/Views/Learning"));
const LearningDetail = lazy(() => import("./View/Views/LearingDetail"));
const Message = lazy(() => import("./View/Views/Messages"));

// Admin
const AdminIndex = lazy(() => import("./Admin/Index"));
const AdminDashboard = lazy(() => import("./Admin/Views/Dashboard"));
const AdminPeople = lazy(() => import("./Admin/Views/People"));
const AdminReporting = lazy(() => import("./Admin/Views/Reporting"));
const AdminSetting = lazy(() => import("./Admin/Views/Settings"));
const AdminLearning = lazy(() => import("./Admin/Views/Learning"));
const AdminAddLearning = lazy(() => import("./Admin/Views/AddLearning"));
const AdminEditLearning = lazy(() => import("./Admin/Views/EditLearning"));
const AdminDetailLearning = lazy(() => import("./Admin/Views/DetailLearning"));
const StaticPage = lazy(() => import("./Admin/Views/StaticPage"));
const EmailTemplate = lazy(() => import("./Admin/Views/EmailTemplate"));
const MasterData = lazy(() => import("./Admin/Views/MasterData"));
const Profile = lazy(() => import("./Admin/Views/Profile"));
const SubAdmin = lazy(() => import("./Admin/Views/SubAdmin"));
const School = lazy(() => import("./Admin/Views/School"));
const SchoolCategory = lazy(() => import("./Admin/Views/SchoolCategory"));
const Student = lazy(() => import("./Admin/Views/Student"));

// Admin Auth
const AdminLogin = lazy(() => import("./Admin/Views/Auth/View/Login"));
const AdminForgotPassword = lazy(() => import("./Admin/Views/Auth/View/ForgotPassword"));
const AdminChangePassword = lazy(() => import("./Admin/Views/Auth/View/ChangePassword"));
const SubAdminChangePassword = lazy(() => import("./Admin/Views/Auth/View/SubChangePassword"));

// View Auth
const ViewLogin = lazy(() => import("./Auth/views/Login"));
const ViewForgotPassword = lazy(() => import("./Auth/views/ForgotPassword"));
const ViewChangePassword = lazy(() => import("./Auth/views/ChangePassword"));
// const ViewSignUp = lazy(() => import("./Auth/views/SignUp"));
const ViewProfile = lazy(() => import("./View/Views/Profile"));

// Auth Index
const AuthIndex = lazy(() => import("./Auth/index"));
const AuthIndexAdmin = lazy(() => import("./Auth/IndexAdmin"));

// ORG/SCHOOL Auth Index
const OSIndex = lazy(() => import("./OrgSchool/Index"));
const OSAuthIndex = lazy(() => import("./OrgSchool/Views/Index"));
const OSFORGOTPASSWORD = lazy(() => import("./OrgSchool/Auth/Views/ForgotPassword"));
const OSCHANGEPASSWORD = lazy(() => import("./OrgSchool/Auth/Views/ChangePassword"));
const OSDashboard = lazy(() => import("./OrgSchool/Views/Dashboard"));
const OSProfile = lazy(() => import("./OrgSchool/Views/Profile"));
const OSSettings = lazy(() => import("./OrgSchool/Views/Settings"));

// Error
const PageNotFound = lazy(() => import("./Home/Error"));

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <AppWrapper>
      <Provider store={store}>
        <BrowserRouter basename="/">
          <Suspense fallback={<></>}>
            <ErrorBoundary FallbackComponent={ErrorFallbackPage}>
              {/* Start */}
              <Routes>
                <Route path="/" element={<AuthIndex />}>
                  <Route path="" element={<ViewLogin />} />
                  <Route path="forgot-password" element={<ViewForgotPassword />} />
                  <Route path="change-password/:token" element={<ViewChangePassword />} />
                </Route>

                <Route path="/" element={<ViewIndex navigationData={ViewNavigation} roleValue="learner" />}>
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="my-learning">
                    <Route path="" element={<Learning />} />
                  </Route>
                  <Route path="my-message" element={<Message />} />
                  <Route path="*" element={<PageNotFound />} />
                  <Route path="profile" element={<ViewProfile />} />
                </Route>

                <Route path="/admin" element={<AuthIndexAdmin />}>
                  <Route path="" element={<AdminLogin />}></Route>
                  <Route path="forgot-password" element={<AdminForgotPassword />}></Route>
                  <Route path="change-password/:token" element={<AdminChangePassword />}></Route>
                </Route>

                <Route path="/sub-admin" element={<AuthIndexAdmin />}>
                  <Route path="change-password/:token" element={<SubAdminChangePassword />}></Route>
                </Route>

                <Route path="/sub-admin" element={<AdminIndex navigationData={AdminNavigation} roleValue={"sub-admin"} navigationData2={SubAdminNavigation} />}>
                  <Route path="dashboard" element={<AdminDashboard />}></Route>
                </Route>

                <Route path="/admin" element={<AdminIndex navigationData={AdminNavigation} roleValue={"admin"} navigationData2={SubAdminNavigation} />}>
                  <Route path="dashboard" element={<AdminDashboard />} />
                  <Route path="learning">
                    <Route path="" element={<AdminLearning />} />
                    <Route path="add" element={<AdminAddLearning />} />
                    <Route path="edit/:courseId" element={<AdminAddLearning />} />
                    <Route path=":courseId" element={<AdminDetailLearning />} />
                  </Route>
                  <Route path="people" element={<AdminPeople />} />
                  <Route path="reporting" element={<AdminReporting />} />
                  <Route path="settings" element={<AdminSetting />} />
                  <Route path="email-template" element={<EmailTemplate />} />
                  <Route path="static-page" element={<StaticPage />} />
                  <Route path="master-data" element={<MasterData />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="sub-admin" element={<SubAdmin />} />
                  <Route path="org-school" element={<School />} />
                  <Route path="category" element={<SchoolCategory />} />
                  <Route path="learner" element={<Student />} />
                </Route>

                <Route path="/org-school" element={<OSAuthIndex />}>
                  <Route path="forgot-password" element={<OSFORGOTPASSWORD />}></Route>
                  <Route path="change-password/:token" element={<OSCHANGEPASSWORD />}></Route>
                </Route>

                <Route path="/org-school" element={<OSIndex navigationData={ORGSCHOOLNavigation} roleValue={"org-school"} />}>
                  <Route path="dashboard" element={<OSDashboard />} />
                  <Route path="learning">
                    <Route path="" element={<AdminLearning />} />
                    <Route path="add" element={<AdminAddLearning />} />
                    <Route path="edit/:courseId" element={<AdminAddLearning />} />
                    <Route path=":courseId" element={<AdminDetailLearning />} />
                  </Route>
                  <Route path="learner" element={<Student />} />
                  <Route path="profile" element={<OSProfile />} />
                  <Route path="settings" element={<OSSettings />} />
                  <Route path="category" element={<SchoolCategory />} />
                </Route>

                <Route path="/my-learning/:courseId" element={<LearningDetail />} />
              </Routes>

              {/* Start End*/}
            </ErrorBoundary>
          </Suspense>
        </BrowserRouter>
        <ToastHandler />
      </Provider>
    </AppWrapper>
  </React.StrictMode>
);
