import navigation from "../Assets/navigation";
import svg from "../Assets/svg";

//  View Navigation Bar
export const ViewNavigation = [
  {
    path: "/dashboard",
    label: "Dashboard",
    svg: navigation?.dashboard,
  },
  {
    path: "my-learning",
    label: "My Learning",
    svg: navigation?.learning,
  },
  {
    path: "my-message",
    label: "My Messages",
    svg: navigation?.message,
  },
];

//  Admin Navigation Bar
export const AdminNavigation = [
  {
    path: "/admin/dashboard",
    label: "Dashboard",
    svg: navigation?.dashboard,
  },
  {
    path: "/admin/learning",
    label: "Learning Content",
    svg: navigation?.learning,
  },

  {
    path: "/admin/org-school",
    label: "Organisation/School",
    svg: navigation?.School,
  },
  {
    path: "/admin/learner",
    label: "Learner",
    svg: navigation?.Student,
  },
  {
    path: "/admin/category",
    label: "Org/School Course Categories",
    svg: navigation?.orgSchoolCategory,
  },
  {
    path: "/admin/reporting",
    label: "Reporting",
    svg: navigation?.dashboard,
  },
  {
    path: "/admin/settings",
    label: "Setting",
    svg: svg?.setting,
  },
  {
    path: "email-template",
    label: "Email Template",
    svg: navigation?.emailTemplate,
  },
  {
    path: "static-page",
    label: "Static Page",
    svg: navigation?.staticPage,
  },
  {
    path: "master-data",
    label: "Master Data",
    svg: navigation?.masterData,
  },
];

export const SubAdminNavigation = [
  {
    path: "/admin/dashboard",
    label: "Dashboard",
    svg: navigation?.dashboard,
  },
  {
    path: "/admin/learning",
    label: "Learning Content",
    svg: navigation?.learning,
  },
  {
    path: "/admin/org-school",
    label: "Organisation/School",
    svg: navigation?.School,
  },
  {
    path: "/admin/learner",
    label: "Learner",
    svg: navigation?.Student,
  },
  {
    path: "/admin/category",
    label: "Org/School Course Categories",
    svg: navigation?.orgSchoolCategory,
  },
  {
    path: "/admin/reporting",
    label: "Reporting",
    svg: navigation?.dashboard,
  },
  {
    path: "/admin/settings",
    label: "Setting",
    svg: svg?.setting,
  },
];

export const ORGSCHOOLNavigation = [
  {
    path: "/org-school/dashboard",
    label: "Dashboard",
    svg: navigation?.dashboard,
  },
  {
    path: "/org-school/learning",
    label: "Learning Content",
    svg: navigation?.learning,
  },
  {
    path: "/org-school/category",
    label: "Course Categories",
    svg: navigation?.orgSchoolCategory,
  },
  {
    path: "/org-school/learner",
    label: "Learner",
    svg: navigation?.Student,
  },
  {
    path: "/org-school/settings",
    label: "Setting",
    svg: svg?.setting,
  },
];

// Dummy Data
export const TabMap = [
  { value: "COURSES", description: "Your recent courses" },
  { value: "ASSESSMENTS", description: "Your recent performance" },
  { value: "MESSAGES", description: "Latest forum topics & comments" },
];

export const staticMap = [
  {
    _id: "652faf1b7b57b0f18b5d6816",
    name: "Privacy Policy",
    description: "Privacy Policy",
    html: "<p>Privacy Policy</p>",
    __v: 0,
  },
];

export default ViewNavigation;

export const presentColor = [
  {
    label: "Select Color",
    colors: [
      "#CD5C5C",
      "#F08080",
      "#FA8072",
      "#E9967A",
      "#DC143C",
      "#FF0000",
      "#B22222",
      "#8B0000",
      "#FF69B4",
      "#FF1493",
      "#C71585",
      "#DB7093",
      "#FFA07A",
      "#FF7F50",
      "#FF4500",
      "#FF8C00",
      "#FFA500",
      "#FFD700",
      "#BDB76B",
      "#FF00FF",
      "#BA55D3",
      "#9370DB",
      "#663399",
      "#8A2BE2",
      "#9400D3",
      "#9932CC",
      "#8B008B",
      "#800080",
      "#4B0082",
      "#32CD32",
      "#2E8B57",
      "#008000",
      "#9ACD32",
      "#66CDAA",
      "#48D1CC",
      "#4682B4",
      "#00BFFF",
      "#4169E1",
      "#00008B",
      "#DEB887",
      "#BC8F8F",
      "#800000",
      "#808080",
      "#708090",
    ],
  },
];
