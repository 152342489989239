import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const isDev = false;

export const url = isDev ? "http://localhost:5001/api/" : "https://lightwave-backend.octivo.io:5001/api/";
export const urlV1 = `${url}v1/`;
export const token = isDev ? "b2808f49-f938-49c2-bec5-aa01f6363f26" : "5ba3f2056-47b8-4d96-b571-770924ae116c";
export const bucketURL = isDev ? "https://lwlms-filli-cafe.s3.ap-southeast-2.amazonaws.com/" : "https://lwlms-filli-cafe.s3.ap-southeast-2.amazonaws.com/";
export const courseImageUrl = `${bucketURL}course/image/`;
export const courseDocsUrl = `${bucketURL}course/doc/`;
export const baseUrlProfile = `${bucketURL}profile/`;
export const CSVSample = `${bucketURL}General/ORG_SCHOOL_SAMPLE.csv`;

export const baseUrl = "https://lwlms-filli-cafe.s3.ap-southeast-2.amazonaws.com/";

// Time
export const momentDateTimeFormat = "DD MMM, YYYY";
export const momentDateTimeFormatAMPM = "DD MMM YYYY, h:mm:ss A";
export const dayjsDateFormat = "DD/MM/YY";
export const momentDDMMYY = (data) => moment(data).format(momentDateTimeFormat);
export const momentDDMMYYAMPM = (data) => moment(data).format(momentDateTimeFormatAMPM);

// Utils Function
export const dispatchLoading = (dispatch, scope, status) => dispatch({ type: "SET_LOADING", payload: { scope, status } });
export const dispatchToast = (dispatch, scope, status) => dispatch({ type: "SET_TOAST", payload: { scope, status } });
export const dispatchError = (dispatch, scope, status) => dispatch({ type: "SET_ERROR", payload: { scope, status } });
export const catchHandler = (dispatch, scope) => (err) => {
  if (err.code === "ERR_NETWORK") dispatchToast(dispatch, "error", "Unable to connect to server");
  else if (err?.response?.status !== 401) dispatchError(dispatch, scope, err?.response?.data?.error);
  else dispatchToast(dispatch, "error", err?.response?.data?.msg);
  dispatchLoading(dispatch, scope, false);
  dispatchError(dispatch, scope, err?.response?.data?.error);
};

export const elseHandler = (dispatch, scope, data) => {
  dispatchToast(dispatch, "error", data?.msg);
  dispatchError(dispatch, scope, data?.error);
};

export const elseHandler2 = (dispatch, scope, data) => {
  dispatchError(dispatch, scope, data?.error);
};

export const ProtectedRoute = ({ roleName, children }) => {
  const { role } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (localStorage.getItem("userAccessToken") !== null && localStorage.getItem("userAccessToken") !== undefined && role === roleName)
    return <React.Fragment>{children}</React.Fragment>;
  else navigate("/", { replace: true });
};

export const ProtectedRouteAdmin = ({ roleName, children }) => {
  const { role } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (localStorage.getItem("userAccessToken") !== null && localStorage.getItem("userAccessToken") !== undefined && (role === "admin" || role === "sub-admin"))
    return <React.Fragment>{children}</React.Fragment>;
  else navigate(`/`, { replace: true });
};

export const ProtectedRouteSchool = ({ roleName, children }) => {
  const { role } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (localStorage.getItem("userAccessToken") !== null && localStorage.getItem("userAccessToken") !== undefined && role === roleName)
    return <React.Fragment>{children}</React.Fragment>;
  else navigate(`/`, { replace: true });
};

export const Reset = () => {
  const navigate = useNavigate();
  localStorage.removeItem("userAccessToken");
  navigate("/login");
};

export const ErrorFallbackPage = ({ error, resetErrorBoundary }) => {
  const { pathname } = useLocation();
  const originalPathname = useRef(pathname);

  useEffect(() => {
    if (pathname !== originalPathname.current) resetErrorBoundary();
  }, [pathname, resetErrorBoundary]);

  return <></>;
};
