import { combineReducers, configureStore } from "@reduxjs/toolkit";

const authReducer = (
  state = {
    isLoggedIn: false,
    role: -1,
    isAuthenticated: -1,
  },
  action
) => {
  switch (action.type) {
    case "AUTH_LOAD_SUCCESS":
      return {
        ...state,
        isAuthenticated: action?.payload?.isAuthenticated,
        role: action?.payload?.role,
        isLoggedIn: true,
        user: action?.payload,
      };

    case "AUTH_LOAD_FAILURE":
      return {
        ...state,
        isAuthenticated: false,
        isLoggedIn: false,
        role: -1,
      };

    case "AUTH_LOGIN_SUCCESS":
      return {
        ...state,
        role: action.payload.role,
        isLoggedIn: true,
      };

    case "AUTH_LOGOUT_SUCCESS":
      return {
        isAuthenticated: false,
        isLoggedIn: false,
        user: undefined,
        role: -1,
      };

    case "LOGIN_SUCCESS":
      return {
        isLoggedIn: true,
        user: action.payload,
      };

    case "STATIC_LOGIN_LIST_SUCCESS":
      return {
        ...state,
        loginList: action.payload,
      };
    case "LOGOUT_SUCCESS":
      return {
        isLoggedIn: false,
        role: -1,
        isAuthenticated: -1,
      };

    default:
      return state;
  }
};

const errorReducer = (state = { authError: undefined, carError: undefined }, action) => {
  switch (action.type) {
    case "SET_ERROR":
      const temp = {};
      const { payload } = action;

      if (payload?.status?.length) {
        payload.status.forEach((ele) => {
          if (ele.param === "_error") {
            ele.nestedErrors?.forEach((ele1) => {
              const key = ele1.param.split(".").pop();
              temp[key] = ele1.msg;
            });
          } else if (ele.param?.includes("updates.")) {
            const key = ele.param.split(".").slice(1).join(".");
            temp[key] = ele.msg;
          } else {
            temp[ele.path] = ele.msg;
          }
        });
      }

      return { ...state, [`${payload.scope}Error`]: temp };

    default:
      return state;
  }
};

const loadingReducer = (state = { authLoading: false, carLoading: false, employeeLoading: false, bookingLoading: false, bookingListLoading: false }, action) => {
  /**
   * UNIVERSAL LOADING HANDLER
   */
  switch (action.type) {
    case "SET_LOADING":
      return Object.assign({}, state, {
        [`${action.payload.scope}Loading`]: action.payload.status,
      });
    default:
      return state;
  }
};

const msgReducer = (state = { success: undefined, error: undefined }, action) => {
  /**
   * UNIVERSAL TOAST HANDLER
   */
  switch (action.type) {
    case "SET_TOAST":
      return Object.assign({}, state, {
        [`${action.payload.scope}`]: action.payload.status || "Something went wrong!",
      });
    case "CLEAR_TOAST":
      return { ...state, error: undefined, success: undefined };
    default:
      return state;
  }
};

const initialState = {
  isDarkMode: localStorage.getItem("darkMode") !== null ? (localStorage.getItem("darkMode") === "false" ? false : true) : false,
};

export const switchReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_SWITCH":
      localStorage.setItem("darkMode", action.isDarkMode);
      return { ...state, isDarkMode: action.isDarkMode };
    default:
      return state;
  }
};

export const LearningReducer = (state = {}, action) => {
  switch (action.type) {
    case "STATIC_LEARNING_LIST_SUCCESS":
      return {
        ...state,
        learningList: action.payload,
      };
    default:
      return state;
  }
};

const initialPercentState = {
  percent: localStorage.setItem("progressBar", JSON.stringify(0)),
};

export const PercentReducer = (state = initialPercentState, action) => {
  switch (action.type) {
    case "STATIC_PERCENT_SUCCESS":
      return {
        ...state,
        percent: action.payload,
      };
    default:
      return state;
  }
};

const staticPageListReducer = (state = {}, action) => {
  switch (action.type) {
    case "STATIC_STATIC_LIST_SUCCESS":
      return {
        ...state,
        staticList: action.payload,
      };

    default:
      return state;
  }
};

const schoolReducer = (state = {}, action) => {
  switch (action.type) {
    case "STATIC_SCHOOL_LIST_SUCCESS":
      return {
        ...state,
        schoolList: action.payload,
      };

    case "STATIC_SCHOOL_COUNT_SUCCESS":
      return {
        ...state,
        schoolCount: action.payload,
      };

    default:
      return state;
  }
};

const studentReducer = (state = {}, action) => {
  switch (action.type) {
    case "STATIC_STUDENT_LIST_SUCCESS":
      return {
        ...state,
        studentList: action.payload,
      };

    case "STATIC_STUDENT_COUNT_SUCCESS":
      return {
        ...state,
        studentCount: action.payload,
      };

    default:
      return state;
  }
};

const emailReducer = (state = {}, action) => {
  switch (action.type) {
    case "STATIC_EMAIL_TEMPLATE_LIST_SUCCESS":
      return {
        ...state,
        emailTemplateList: action.payload,
      };

    default:
      return state;
  }
};

const countReducer = (state = {}, action) => {
  switch (action.type) {
    case "STATIC_SCHOOL_COUNT_SUCCESS":
      return {
        ...state,
        schoolCount: action.payload,
      };

    default:
      return state;
  }
};

const subAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case "STATIC_SUB_ADMIN_LIST_SUCCESS":
      return {
        ...state,
        subAdminList: action.payload,
      };

    case "STATIC_SUB_ADMIN_COUNT_SUCCESS":
      return {
        ...state,
        subAdminCount: action.payload,
      };

    default:
      return state;
  }
};

const badgeReducer = (state = {}, action) => {
  switch (action.type) {
    case "STATIC_BADGE_LIST_SUCCESS":
      return {
        ...state,
        badgeList: action.payload,
      };

    case "STATIC_BADGE_COUNT_LIST_SUCCESS":
      return {
        ...state,
        badgeCount: action.payload,
      };

    default:
      return state;
  }
};

const courseCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case "STATIC_CATEGORY_LIST_SUCCESS":
      let cl = {};
      action.payload.forEach((ele) => (cl[ele?._id] = ele));
      return {
        ...state,
        categoryList: cl,
      };

    case "STATIC_CATEGORY_COUNT_LIST_SUCCESS":
      return {
        ...state,
        categoryCount: action.payload,
      };

    default:
      return state;
  }
};

const courseReducer = (state = {}, action) => {
  switch (action.type) {
    case "STATIC_COURSE_LIST_SUCCESS":
      let cl = {};
      action.payload.forEach((ele) => (cl[ele?._id] = ele));
      return {
        ...state,
        courseList: cl,
      };

    case "STATIC_COURSE_LIST_ARRAY_SUCCESS":
      return {
        ...state,
        courseAllList: action.payload,
      };

    case "STATIC_COURSE_COUNT_LIST_SUCCESS":
      return {
        ...state,
        courseCount: action.payload,
      };
    case "FETCH_COURSE_LEARNER_SUCCESS":
      return {
        ...state,
        courseLearner: action.payload,
      };
    case "FETCH_COURSE_LEARNER_COUNT_SUCCESS":
      return {
        ...state,
        courseLearnerCount: action.payload,
      };
    default:
      return state;
  }
};

const dashboardReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_DASHBOARD_DATA":
      return {
        ...state,
        learnerDashboard: action.payload,
      };

    default:
      return state;
  }
};

const store = configureStore({
  reducer: combineReducers({
    auth: authReducer,
    error: errorReducer,
    loading: loadingReducer,
    msg: msgReducer,
    isDarkMode: switchReducer,
    learning: LearningReducer,
    percent: PercentReducer,
    static: staticPageListReducer,
    school: schoolReducer,
    student: studentReducer,
    emailTemplate: emailReducer,
    count: countReducer,
    subAdmin: subAdminReducer,
    badge: badgeReducer,
    courseCategory: courseCategoryReducer,
    course: courseReducer,
    dashboard: dashboardReducer,
  }),
});

export default store;
